import axios from "@/common/interceptors.service";
import ApiService from "@/common/api.service";

export default {
  createClass(data) {
    return ApiService.post("/session-class/create", data);
  },
  getClasses(data) {
    return ApiService.query("/session-class/list", data);
  },
  getClassesForTeacher(data){
    return ApiService.query("/session-class/list-for-teacher", data);
  },
  getClassesForStudent(data){
    return ApiService.query("/session-class/list-for-student", data);
  },
  getClassDetail(classId) {
    return ApiService.query(`/session-class/${classId}`);
  },
  updateClass(classId, data) {
    return ApiService.patch(`/session-class/${classId}`, data);
  },
  addStudentsToClass(data) {
    return ApiService.post("/session-class/add-students", data);
  },
  removeStudents(data) {
    return ApiService.delete("/session-class/remove-students", data);
  },
  addTeachersToClass(data) {
    return ApiService.post("/session-class/add-teachers", data);
  },
  removeTeachers(data) {
    return ApiService.delete("/session-class/remove-teachers", data);
  },
  updateClassExams(data) {
    return ApiService.patch("/session-class-exam/update", data);
  },
  getClassExamOverView(data) {
    return ApiService.query("/session-class-student-exam/overview", data);
  },
  getMyClassExamOverView(data) {
    return ApiService.query("/session-class-student-exam/my-exams-overview", data);
  },
  getTestAnalysis(data) {
    return ApiService.query("/session-class-student-exam/test-analysis", data);
  },
  studentJoinClass(invitationCode) {
    return ApiService.post(`session-class/join/${invitationCode}`);
  },
  testToStudents(id, data) {
    return ApiService.post(`session-class-exam/${id}/distributing-assigned-test`, data);
  },
  mailToStudents(id, data) {
    return ApiService.post(`session-class-exam/${id}/mail-distributing-assigned-test`, data);
  },
  getClassTestDetail(id) {
    return ApiService.query(`/session-class-exams/${id}`);
  },
  getAdaptiveExams(id, data) {
    return ApiService.query(`/session-class-exams/${id}/user-exams`, data);
  },
  getAdaptiveExamsDetail(id, data) {
    return ApiService.query(`/exams/${id}/analysis`, data);
  }
};
